import React from 'react';
import { PageProps } from 'gatsby';
import { makeStyles } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {
  Link
} from 'gatsby-theme-material-ui';

import FirmName from './FirmName';
import ActionButton from './ActionButton';

const generateStyles = makeStyles((theme) => ({
  AppBar: {
    marginTop: theme.spacing(2.5),
    background: 'none'
  },
  Toolbar: {
    padding: '0 0 0 0',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  ToolbarText: {
    flexGrow: 1,
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1.62, 0.62, 0.62, 0.62),
      marginTop: theme.spacing(1.62),
      margin: theme.spacing(1.38),
      textAlign: 'center',
      width: '100%'
    }
  },
  Nav: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  LinkWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    margin: theme.spacing(0, 0.62)
  },
  Link: {
    margin: theme.spacing(1.38),
    // fontWeight: 500,
    textTransform: 'none'
  },
  ButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap'
  }
}));

function Header(): JSX.Element {
  const myClasses = generateStyles();
  return (
    <AppBar className={myClasses.AppBar} position="static" color="inherit" elevation={0}>
      <Toolbar className={myClasses.Toolbar}>
        <Link to="/" variant="h6" underline="none" className={myClasses.ToolbarText} noWrap><FirmName /></Link>
        <nav className={myClasses.Nav}>
          <span className={myClasses.LinkWrapper}>
            <Link to="/#whatwedo" variant="button" color="textPrimary" underline="hover" className={myClasses.Link} noWrap>What We Do</Link>
            <Link to="/#ourpromise" variant="button" color="textPrimary" underline="hover" className={myClasses.Link} noWrap>Our Promise</Link>
          </span>
          <span className={myClasses.ButtonWrapper}>
            <ActionButton variant="contained" color="primary" to="mailto:hello@microsaascapital.com">Get in touch</ActionButton>
          </span>
        </nav>
      </Toolbar>
    </AppBar>
  );
}

export default Header;

/*
  <Typography variant="h5" className={myClasses.ToolbarText} noWrap><strong style={{ fontWeight: 600 }}>testmail</strong>.app</Typography>
  <ActionButton variant="contained" color="primary" onClick={() => { props.history.push('/signup'); }}>Signup</ActionButton>
  <ActionButton variant="outlined" color="primary" onClick={() => { props.history.push('/signin'); }} invert>Signin</ActionButton>
*/