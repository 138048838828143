import React from 'react';
import ThemeTopLayout from 'gatsby-theme-material-ui-top-layout/src/components/top-layout';

// import Container from '@material-ui/core/Container';

export default function TopLayout({ children, theme }) {
  return <ThemeTopLayout theme={theme}>{children}</ThemeTopLayout>;
  // return (
  //   <Container maxWidth="lg">
  //     <ThemeTopLayout theme={theme}>{children}</ThemeTopLayout>
  //   </Container>
  // );
}