import React from 'react';

export default function CanadaIcon() {
  return (
    <g>
      <svg fill="#EF3340" style={{ shapeRendering: 'geometricPrecision', textRendering: 'geometricPrecision', imageRendering: 'optimizeQuality' }} viewBox="0 0 100 125" x="0px" y="0px" fillRule="evenodd" clipRule="evenodd">
        <g>
          <path d="M92.14,50.969c0.048,0.139-0.284,0.38-0.499,0.556c-6.126,4.987-12.22,10.063-18.512,15.063  c-0.921,0.731-2.528,1.54-2.446,2.946c0.035,0.58,0.43,1.363,0.669,2.001c0.703,1.886,1.539,3.992,2.223,5.782  c-5.35-0.794-10.756-1.912-16.232-2.837c-1.798-0.303-3.575-0.753-5.394-0.834c-0.053,6.956,0.388,14.193,0.502,21.291  c-1.501,0-3.001,0-4.502,0c0.26-6.246,0.26-12.549,0.556-18.899c0.039-0.83,0.214-1.709-0.111-2.501  c-0.915,0.029-1.822,0.263-2.725,0.444c-6.286,1.264-12.744,2.422-19.066,3.669c0.435-1.134,0.978-2.388,1.5-3.726  c0.491-1.259,1.209-2.581,1.333-3.834c0.212-2.135-1.479-2.916-2.724-3.947c-6.059-5.022-12.8-10.267-18.512-15.175  c0.802-0.342,1.732-0.697,2.724-1.114c0.793-0.333,2.334-0.82,2.669-1.333c0.63-0.963-0.097-2.772-0.388-3.836  c-0.989-3.607-2.094-7.58-3.003-10.95c2.513,0.566,5.817,1.272,8.617,1.834c1.621,0.325,3.771,0.921,4.725-0.334  c0.488-0.645,0.83-1.986,1.168-2.835c0.388-0.977,0.776-1.911,1.111-2.891c0.541,0.342,0.917,0.932,1.334,1.445  c2.114,2.596,4.167,5.111,6.615,7.561c0.42,0.419,0.846,0.891,1.445,1.39c0.44,0.365,1.204,1.116,1.834,1  c0.913-0.167,0.549-2.285,0.389-3.058c-1.529-7.426-2.989-15.065-4.558-22.402c0.19-0.125,0.328,0.146,0.443,0.223  c1.909,1.252,3.75,2.516,5.838,3.78c0.643,0.389,1.6,1.128,2.445,1.056c1.155-0.1,1.904-2.59,2.39-3.558  c1.355-2.693,2.659-5.332,3.947-7.894c0.681-1.355,1.277-2.789,2.001-4.114c0.048-0.01,0.053,0.023,0.056,0.057  c2.281,4.503,4.678,9.042,7.06,13.674c0.304,0.592,0.636,1.711,1.111,1.835c0.876,0.225,2.059-0.595,2.669-0.945  c2.288-1.313,4.659-2.747,6.837-3.891c-1.64,7.356-3.249,14.853-4.947,22.346c-0.159,0.7-0.646,2.869,0.11,3.114  c0.699,0.225,1.6-0.577,1.946-0.889c0.87-0.786,1.608-1.832,2.444-2.78c2.386-2.7,4.896-5.712,7.283-8.227  c0.336,0.936,0.737,1.904,1.112,2.945c0.361,1.003,0.542,2.181,1,3.058c0.835,1.595,3.682,0.636,5.281,0.278  c2.62-0.585,5.597-1.233,8.227-1.778c-1.111,3.803-2.269,8.312-3.391,12.507c-0.34,1.277-0.557,2.047,0.445,2.724  C88.541,49.876,90.595,50.273,92.14,50.969z" />
        </g>
      </svg>
    </g>
  );
}

// #757575
// #EF3340
