import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 475, // originally 768px
      md: 768, // originally 960px
      lg: 1024, // originally 1280px
      xl: 1280 // originally 1920px
    }
  },
  palette: {
    action: {
      active: 'rgba(65,55,104,0.54)',
      // active: 'rgba(65,55,104,0.18)',
      hover: 'rgba(65,55,104,0.08)',
      selected: 'rgba(65,55,104,0.14)'
    },
    primary: {
      main: '#000080'
      // main: '#413768'
    },
    secondary: {
      main: '#CE1E5B'
    },
    background: {
      default: '#ffffff'
    },
    text: {
      primary: '#000080'
      // primary: '#413768'
    }
  },
  typography: {
    fontFamily: [
      'Hind Vadodara',
      'sans-serif'
    ].join(','),
    fontSize: 18,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500, // was 600
    fontWeightBold: 500, // was 700
    h1: {},
    h2: {},
    h3: {},
    h4: {},
    h5: {},
    h6: {},
    subtitle1: {},
    subtitle2: {},
    body1: {},
    body2: {},
    button: {
      textTransform: 'none',
      lineHeight: 1.6
    },
    caption: {},
    overline: {}
  }
});

export default theme;
