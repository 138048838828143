import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Button
} from 'gatsby-theme-material-ui';

const StyleGenerators = {
  Button: makeStyles((theme) => ({
    root: {
      margin: theme.spacing(0.62),
      borderRadius: '25px',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: '0 0 0 0.2rem rgba(65, 55, 104, 0.38)'
      }
    },
    label: {
      paddingBottom: '1px'
    }
  })),
  InvertedButton: makeStyles((theme) => ({
    root: {
      margin: theme.spacing(0.62),
      borderRadius: '25px',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: '0 0 0 0.2rem rgba(65, 55, 104, 0.38)',
        background: 'none'
      }
    }
  }))
};

function ActionButton(props: any): JSX.Element {
  const Styles = {
    Button: StyleGenerators.Button(),
    InvertedButton: StyleGenerators.InvertedButton()
  };
  let invert = false;
  if (props.invert) {
    invert = props.invert;
  }
  delete props.invert;
  const buttonProps = props;
  return (
    <Button
      classes={invert ? Styles.InvertedButton : Styles.Button}
      {...buttonProps}
    >{props.children}</Button>
  );
}

export default ActionButton;
