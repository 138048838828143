import * as React from 'react';
import Container from '@material-ui/core/Container';

import Header from './Header';
import Footer from './Footer';

const Layout = ({ children }) => (
  <Container maxWidth="lg">
    <Header />
    {children}
    <Footer />
  </Container>
);

export default Layout;