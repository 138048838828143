import React from 'react';
import { makeStyles } from '@material-ui/styles';

const generateStyles = makeStyles((theme) => ({
  MicroText: {
    fontWeight: 600
  },
  SaaSText: {
    fontWeight: 600
  },
  CapitalText: {
    fontWeight: 400
  }
}));

export default function FirmName(): JSX.Element {
  const myClasses = generateStyles();
  return (
    <React.Fragment>
      <span className={myClasses.MicroText}>Micro</span>
      <span className={myClasses.SaaSText}> SaaS</span>
      <span className={myClasses.CapitalText}> Capital</span>
    </React.Fragment>
  );
}