import React from 'react';
import {
  Button,
  Link
} from 'gatsby-theme-material-ui';

import { makeStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import TextField from '@material-ui/core/TextField';

import FirmName from './FirmName';
import CanadaIcon from './CanadaIcon';

const generateStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    },
    ul: {
      margin: 0,
      padding: 0
    },
    li: {
      listStyle: 'none'
    }
  },
  FooterGrid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  FooterListItem: {
    marginBottom: '1em'
  },
  FooterTitle: {
    marginBottom: '1em',
    fontWeight: theme.typography.fontWeightMedium,
    userSelect: 'none',
    textTransform: 'none'
    // fontSize: '1rem'
    // lineHeight: 1
  },
  FooterSubTitle: {
    fontWeight: theme.typography.fontWeightRegular,
    textDecoration: 'underline'
  },
  FooterLink: {
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: 'none'
    // fontSize: '1rem'
  },
  FooterFirmTitle: {
    marginBottom: '1em',
    fontWeight: 400,
    userSelect: 'none',
    // fontSize: '1rem',
    // lineHeight: 1,
    textTransform: 'none'
  },
  FooterFirmLink: {
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: 'none'
    // fontSize: '1rem'
    // lineHeight: 1
  },
  FooterFirmAddress: {
    // fontWeight: 300,
    textTransform: 'none'
    // userSelect: 'none'
    // fontSize: '1rem'
    // lineHeight: 1,
    // opacity: 0.62
  },
  CanadaIcon: {
    // [theme.breakpoints.down('md')]: {
    //   display: 'none'
    // },
    fontSize: '1.5rem !important',
    marginBottom: '-5px',
    marginLeft: '1px',
    opacity: 0.9
  }
}));

function Footer(): JSX.Element {
  const myClasses = generateStyles();
  const muitheme = useTheme();
  const VIEW_D_SM = useMediaQuery(muitheme.breakpoints.down('sm'));
  return (
    <Grid
      container
      spacing={9}
      direction="row"
      justify="center"
      alignItems="flex-start"
      className={myClasses.FooterGrid}
    >
      <Grid
        container
        item
        // alignItems={VIEW_D_SM ? 'center' : 'flex-start'}
        lg={3}
        md={4}
        xs={12}
        style={{ order: VIEW_D_SM ? 2 : 1 }}
        key="Company"
      >
        <Typography variant="body2" color="textPrimary" className={myClasses.FooterFirmTitle} gutterBottom>© <FirmName /></Typography>
        <ul style={{ paddingLeft: VIEW_D_SM ? '0px' : '1px' }}>
          <li key="Canada">
            <Typography variant="body2" color="textPrimary" className={myClasses.FooterFirmAddress} gutterBottom>
              Our team is based in<br />
              Toronto, Canada
              <SvgIcon className={myClasses.CanadaIcon} viewBox="0 -14 125 125">
                <CanadaIcon />
              </SvgIcon>
            </Typography>
            <Typography variant="body2" color="textPrimary" gutterBottom>
              We invest <span style={{ fontWeight: 500 }}>worldwide</span>.
            </Typography>
          </li>
        </ul>
        <ul style={{ paddingTop: '0px', paddingLeft: VIEW_D_SM ? '0px' : '1px' }}>
          {/* <li key="Terms of Use">
            <Link to="/terms" variant="button" underline="always" color="textPrimary" className={myClasses.FooterLink}>Terms of Use</Link>
          </li>
          <li key="Privacy Policy">
            <Link to="/privacy" variant="button" underline="always" color="textPrimary" className={myClasses.FooterLink}>Privacy Policy</Link>
          </li>*/}
          <li key="Contact Us">
            <Link to="mailto:hello@microsaascapital.com" variant="button" underline="always" color="textPrimary" className={myClasses.FooterLink}>Contact Us</Link>
          </li>
        </ul>
      </Grid>
      <Grid
        container
        item
        spacing={7}
        alignItems="flex-start"
        lg={9}
        md={6}
        xs={12}
        style={{ order: VIEW_D_SM ? 1 : 2 }}
        key="LinkBlock"
      >
        <Grid item key="Founders" lg={6} xs={12} style={VIEW_D_SM ? { maxWidth: '450px' } : {}}>
          <Typography variant="body2" color="textPrimary" className={myClasses.FooterTitle} gutterBottom>Founders:</Typography>
          <ul>
            <li key="Say Hello!" className={myClasses.FooterListItem}>
              <Link to="mailto:hello@microsaascapital.com" variant="button" underline="always" color="textPrimary" className={myClasses.FooterSubTitle}>Say Hello!</Link>
              <Typography variant="body2" color="textPrimary" gutterBottom>
                We love meeting fellow founders and following your journey over many years. Send us your blog!
              </Typography>
            </li>
            <li key="Follow Us" className={myClasses.FooterListItem}>
              <Link to="https://form.typeform.com/to/fa8Wt8Rq" variant="button" underline="always" color="textPrimary" className={myClasses.FooterSubTitle}>Get Our Newsletter</Link>
              <Typography variant="body2" color="textPrimary">
                Our best ideas and curated resources for SaaS founders - thoughtful and succinct - max 2 emails a year!
              </Typography>
              {/* <TextField
                id="emailsignup"
                type="email"
                placeholder="founder@domain.com"
                fullWidth
              /> */}
            </li>
          </ul>
        </Grid>
        <Grid item key="Partners" lg={6} xs={12} style={VIEW_D_SM ? { maxWidth: '450px' } : {}}>
          <Typography variant="body2" color="textPrimary" className={myClasses.FooterTitle} gutterBottom>Partners:</Typography>
          <ul>
            <li key="Scout Program" className={myClasses.FooterListItem}>
              <Link to="mailto:hello@microsaascapital.com?subject=Scout%20Program" variant="button" underline="always" color="textPrimary" className={myClasses.FooterSubTitle}>Join Our Scout Program</Link>
              <Typography variant="body2" color="textPrimary" gutterBottom>Connect us with awesome SaaS founders and we'll pay finders fees.</Typography>
            </li>
            <li key="Invest With Us" className={myClasses.FooterListItem}>
              <Link to="mailto:hello@microsaascapital.com?subject=Investor%20Program" variant="button" underline="always" color="textPrimary" className={myClasses.FooterSubTitle}>Invest With Us</Link>
              <Typography variant="body2" color="textPrimary" gutterBottom>We partner with founders to compound sustainable (profitable) growth over the long-term.</Typography>
            </li>
          </ul>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Footer;

/*

<Grid item key="Investors" style={{ maxWidth: '260px' }}>
  <Typography variant="body2" color="textPrimary" className={myClasses.FooterTitle} gutterBottom>Investors</Typography>
  <ul>
    <li key="GraphQL API">
      Get referrals from us!
      We talk to hundreds of businesses outside our domain of expertise.
      We are happy to refer these to investors we trust will treat founders with the same care we do.
      (Learn More Link)
      Invest alongside us! Get in touch.
    </li>
    <li key="Documentation">
      Invest alongside us! Get in touch.
    </li>
    <li key="Join Us">
              <Typography variant="button" color="textPrimary" className={myClasses.FooterSubTitle}>Join Us</Typography>
              <Typography variant="body2" color="textPrimary" gutterBottom>Like operating micro SaaS businesses? Work for us! We value great builders and marketers.</Typography>
            </li>
  </ul>
</Grid>

*/